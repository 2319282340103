<template>
    <div class="base-timer">
        <svg
            class="base-timer__svg"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g class="base-timer__circle">
                <circle
                    class="base-timer__path-elapsed"
                    cx="50"
                    cy="50"
                    r="45"
                ></circle>
                <path
                    :stroke-dasharray="circleDasharray"
                    class="base-timer__path-remaining"
                    :class="remainingPathColor"
                    d="
            M 50, 50
            m -45, 0
            a 45,45 0 1,0 90,0
            a 45,45 0 1,0 -90,0
          "
                ></path>
            </g>
        </svg>
        <span class="base-timer__label" id="timerlabel">{{
            formattedTimeLeft
        }}</span>
    </div>
</template>

<script>
const FULL_DASH_ARRAY = 283;
const WARNING_THRESHOLD = 10;
const ALERT_THRESHOLD = 5;

const COLOR_CODES = {
    info: {
        color: "green"
    },
    warning: {
        color: "orange",
        threshold: WARNING_THRESHOLD
    },
    alert: {
        color: "red",
        threshold: ALERT_THRESHOLD
    }
};

export default {
    name: "timer",
    props: {
        time_limit: { type: Number, default: 20, required: true },
        stopTime: { type: Boolean, default: false },
        startTime: { type: Boolean, default: false },
        continueTime: { type: Boolean, default: false }
    },
    data() {
        return {
            timePassed: 0,
            timerInterval: null
        };
    },

    computed: {
        circleDasharray() {
            return `${(this.timeFraction * FULL_DASH_ARRAY).toFixed(0)} 283`;
        },

        formattedTimeLeft() {
            const timeLeft = this.timeLeft;
            //console.log(timeLeft);
            let hours = Math.floor(timeLeft / 3600);
            //let minutes = timeLeft % 60;

            let minutesLeft = Math.floor(timeLeft - hours * 3600);
            let minutes = Math.floor(minutesLeft / 60);

            let seconds = timeLeft % 60;

            if (hours < 10) {
                hours = `0${hours}`;
            }

            if (minutes < 10) {
                minutes = `0${minutes}`;
            }

            if (seconds < 10) {
                seconds = `0${seconds}`;
            }

            return `${hours}:${minutes}:${seconds}`;
        },

        timeLeft() {
            return this.time_limit - this.timePassed;
        },

        timeFraction() {
            const rawTimeFraction = this.timeLeft / this.time_limit;
            return (
                rawTimeFraction - (1 / this.time_limit) * (1 - rawTimeFraction)
            );
        },

        remainingPathColor() {
            const { alert, warning, info } = COLOR_CODES;

            if (this.timeLeft <= alert.threshold) {
                return alert.color;
            } else if (this.timeLeft <= warning.threshold) {
                return warning.color;
            } else {
                return info.color;
            }
        }
    },

    watch: {
        timeLeft(newValue) {
            if (newValue === 0) {
                this.onTimesUp();
            }
        },
        stopTime: {
            handler: function() {
                this.stopTimer();
                this.timerInterval = null;
            }
        },
        startTime: {
            handler: function() {
                this.timePassed = 0;
                this.timerInterval = null;
                this.startTimer();
            }
        },
        continueTime: {
            handler: function() {
                this.startTimer();
            }
        }
    },

    mounted() {
        this.startTimer();
    },

    methods: {
        onTimesUp() {
            this.$emit("timesUp", true);
            clearInterval(this.timerInterval);
        },
        startTimer() {
            this.timerInterval = setInterval(
                () => (this.timePassed += 1),
                1000
            );
        },
        stopTimer() {
            clearInterval(this.timerInterval);
        }
    }
};
</script>

<style scoped lang="scss">
@media only screen and (max-width: 600px) {
}
.base-timer {
    position: relative;
    width: 170px;
    height: 165px;
    margin-left: auto;
    margin-right: auto;

    &__svg {
        transform: scaleX(-1);
    }

    &__circle {
        fill: none;
        stroke: none;
    }

    &__path-elapsed {
        stroke-width: 7px;
        stroke: grey;
    }

    &__path-remaining {
        stroke-width: 7px;
        stroke-linecap: round;
        transform: rotate(90deg);
        transform-origin: center;
        transition: 1s linear all;
        fill-rule: nonzero;
        stroke: currentColor;

        &.green {
            color: rgb(65, 184, 131);
        }

        &.orange {
            color: orange;
        }

        &.red {
            color: red;
        }
    }

    &__label {
        position: absolute;
        width: 170px;
        height: 165px;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 35px;

        &.resize {
            width: 85px !important;
            top: 8px !important;
            height: 65px !important;
            font-size: 18px !important;
            color: red !important;
        }
    }
}
</style>
