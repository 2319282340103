var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showVideo === 1 && _vm.location != null)?_c('div',[(_vm.showVideo === 1 && _vm.videos.length > 0)?_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-1",modifiers:{"collapse-1":true}}],staticClass:"mt-2",attrs:{"variant":"outline-danger","block":""}},[_vm._v("Zeige Video")]):_vm._e(),_c('b-collapse',{attrs:{"visible":"","id":"collapse-1"}},_vm._l((_vm.videos),function(vid){return _c('b-card',{key:vid.vid,staticClass:"mt-5 video",staticStyle:{"justify-content":"center","display":"flex"}},[_c('video',{attrs:{"width":"100%","height":"65%","controls":"","controlsList":"nodownload noremoteplayback","oncontextmenu":"return false;","playsinline":"","preload":"true"}},[_c('source',{attrs:{"id":"source1","src":_vm.location +
                                'stream/' +
                                _vm.restrictedFolder +
                                '/' +
                                vid.vid,"type":"video/mp4"}})])])}),1)],1):_vm._e(),(
            _vm.showPdf === 1 &&
                _vm.location != null &&
                (_vm.workshop.downloadPdf == null ||
                    _vm.workshop.downloadPdf == false)
        )?_c('div',[(_vm.showPdf === 1 && _vm.documents.length > 0)?_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-3",modifiers:{"collapse-3":true}}],staticClass:"mt-2",attrs:{"variant":"outline-danger","block":""}},[_vm._v("Zeige PDF")]):_vm._e(),_c('b-collapse',{attrs:{"visible":"","id":"collapse-3"}},_vm._l((_vm.documents),function(doc){return _c('b-card',{key:doc.doc,staticClass:"mt-5",staticStyle:{"justify-content":"center","display":"flex"}},[_c('vue-pdf-embed',{attrs:{"source":_vm.location +
                            'stream/' +
                            _vm.restrictedFolder +
                            '/' +
                            doc.doc,"width":_vm.windowWidth}})],1)}),1)],1):_vm._e(),(_vm.showOthers === 1 && _vm.location != null)?_c('div',[(_vm.showOthers === 1 && _vm.files.length > 0)?_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-2",modifiers:{"collapse-2":true}}],staticClass:"mt-2",attrs:{"variant":"outline-danger","block":""}},[_vm._v("Zeige Dokumente")]):_vm._e(),_c('b-collapse',{attrs:{"visible":"","id":"collapse-2"}},_vm._l((_vm.files),function(doc){return _c('div',{key:doc.doc,staticClass:"mt-5",staticStyle:{"justify-content":"center","display":"flex"}},[_c('b-button',{attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.downloadFile(
                            _vm.location +
                                'stream/' +
                                _vm.restrictedFolder +
                                '/' +
                                doc.doc
                        )}}},[_c('b-icon',{attrs:{"icon":"download"}}),_vm._v(" "+_vm._s(doc.doc)+" downloaden ")],1)],1)}),0)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }