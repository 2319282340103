<template>
    <div :class="'keypad-dialog ' + animation">
        <div class="keypad-container">
            <!--<div class="keypad-value">
                <br />
            </div>-->
            <template v-for="n in 12">
                <div :key="n" class="keypad-flex  keypad-class">
                    <div
                        class="keypad delete"
                        v-if="n == 10 && onReset"
                        @click="onReset"
                    >
                        <div class="keypad-center">
                            <strong class="keypad-delete">DEL</strong>
                        </div>
                    </div>
                    <div
                        class="keypad"
                        :ripple="true"
                        v-if="n != 10 && n != 12"
                        @click="onInput(n)"
                    >
                        <div v-if="n < 10" class="keypad-center">{{ n }}</div>
                        <div v-if="n == 11" class="keypad-center">0</div>
                    </div>
                    <div
                        class="keypad delete"
                        v-if="n == 12 && onOk"
                        @click="onOk(n)"
                    >
                        <div v-if="n == 12" class="keypad-center">
                            <strong class="keypad-delete">OK</strong>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: "numeric-keypad",
    data: () => ({
        n: 0,
        animation: "keypad-hide"
    }),
    props: {
        keypadClass: { type: String, default: "keypad-class", required: false },
        show: { type: Boolean, default: true, required: false },
        close: { type: String, default: "Close", required: false },
        onInput: { type: Function, required: true },
        onOk: { type: Function, required: false },
        onReset: { type: Function, required: false }
    },
    methods: {
        closeMe() {
            this.animation = "slideOutDown";
        }
    },
    watch: {
        show() {
            this.animation === "slideInUp"
                ? (this.animation = "slideOutDown")
                : (this.animation = "slideInUp");
        }
    },
    mounted() {
        this.show ? (this.animation = "slideInUp") : (this.animation = "hide");
    }
};
</script>

<style>
.keypad-hide {
    visibility: hidden;
}

.keypad-class {
    /* background: #fafafa; */
    /* border: 0.004rem solid #eaeaea; */
    padding: 10px;
}

.keypad-dialog {
    position: relative;
    bottom: 0;
    left: 25%;
    width: 50%;
    right: 25%;
    display: contents;
}

.keypad-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    min-width: 0;
    flex-direction: row;
}

.keypad-value {
    width: 100%;
    text-align: right;
    padding: 0.5rem;
}

.keypad-flex {
    flex-basis: 33%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 33%;
    min-height: 4rem;
}

.keypad {
    width: 100%;
    height: 100%;
    text-align: center;
    vertical-align: center;
    margin: 0 auto;
    background-color: rgb(250, 161, 93);
    color: white;
    cursor: pointer;
    border-radius: 8px;
}

.keypad-center {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.3rem;
}

.keypad-delete {
    /*font-size: 1.5rem;*/
}
.delete {
    background-color: rgb(102, 136, 181);
}

.slideInUp {
    -webkit-animation-name: slideInUp;
    animation-name: slideInUp;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
@-webkit-keyframes slideInUp {
    0% {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
        visibility: visible;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
@keyframes slideInUp {
    0% {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
        visibility: visible;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

.slideOutDown {
    -webkit-animation-name: slideOutDown;
    animation-name: slideOutDown;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
@-webkit-keyframes slideOutDown {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        visibility: hidden;
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }
}
@keyframes slideOutDown {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        visibility: hidden;
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }
}

.keypad-center {
    font-size: 30px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    overflow: hidden;
    border-radius: 8px;
}

.keypad-center:after {
    content: "";
    background: #90ee90;
    display: block;
    position: absolute;
    padding-top: 300%;
    padding-left: 350%;
    margin-left: -20px !important;
    margin-top: -120%;
    opacity: 0;
    transition: all 0.8s;
}

.keypad-center:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s;
}
</style>
