<template>
    <div>
        <img
            id="image"
            src="../assets/img/certificate.png"
            style="display: none;"
        />
        <img
            id="image2"
            src="../assets/img/certificate_tu.png"
            style="display: none;"
        />
        <b-modal id="bv-modal-timeup" hide-header hide-footer>
            <div style="text-align: center;">
                <b-icon icon="clock" font-scale="5"></b-icon>
            </div>
            <div class="d-block text-center">
                <h3>Zeit ist leider abgelaufen</h3>
            </div>
            <b-button
                class="mt-3"
                variant="outline-danger"
                block
                @click="timeUpAction(true)"
                >Workshop neustarten</b-button
            >
            <b-button
                class="mt-2"
                variant="outline-danger"
                block
                @click="timeUpAction(false)"
                >Zurück zur Auswahl</b-button
            >
        </b-modal>
        <b-modal id="bv-modal-success" hide-header hide-footer>
            <div style="text-align: center;">
                <b-icon
                    :icon="getIcon"
                    animation="fade"
                    font-scale="5"
                    variant="success"
                ></b-icon>
            </div>
            <div class="d-block text-center">
                <h3>Herzlichen Glückwunsch</h3>
                <h4>Sie haben den Workshop erfolgreich abgeschlossen</h4>
            </div>
            <br />
            <b-row class="mb-2">
                <b-col sm="12" class="text-sm">
                    <b-form-group
                        id="input-group-1"
                        :label="$t('entity.label.downloadcert')"
                        label-for="input-1"
                        label-align="center"
                    >
                        <b-button
                            class="mt-2"
                            variant="outline-danger"
                            block
                            :disabled="loadingDownload"
                            @click="downloadPDFBefore()"
                        >
                            <b-spinner
                                small
                                v-if="getloadingDownload"
                            ></b-spinner>
                            Download
                        </b-button>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-button
                class="mt-2"
                variant="outline-danger"
                block
                @click="timeUpAction(false)"
                >Workshop abschliessen</b-button
            >
        </b-modal>
        <b-modal id="bv-modal-cancel" hide-header hide-footer>
            <div style="text-align: center;">
                <b-icon icon="question" font-scale="5"></b-icon>
            </div>
            <div class="d-block text-center">
                <h3>Soll der Workshop beendet werden?</h3>
            </div>
            <b-button
                class="mt-3"
                variant="outline-danger"
                block
                @click="cancelWS(true)"
                >Ja</b-button
            >
            <b-button
                class="mt-2"
                variant="outline-danger"
                block
                @click="cancelWS(false)"
                >Nein</b-button
            >
        </b-modal>
        <b-container v-if="!startWorkshop">
            <div v-if="workshopSelection.length > 1" class="mb-3">
                <b-row class="mb-2">
                    <h3>Workshop auswählen:</h3>
                </b-row>
                <div v-for="workshop in workshopSelection" :key="workshop.id">
                    <b-button
                        class="mt-3"
                        variant="outline-danger"
                        block
                        @click="selectLevel(workshop)"
                    >
                        {{ workshop.description }}</b-button
                    >
                </div>
            </div>
            <div v-if="workshopSelection.length === 0">
                <!--<h3>Workshop {{ workshop ? workshop.section : "" }}</h3>-->
                <h3>{{ workshop ? workshop.description : "" }}</h3>
                <br />
                <span v-html="workshop ? workshop.metainfo : ''"></span>
                <br />
            </div>
            <div
                v-show="loadingWorkshops"
                style="width: 100%;text-align: center"
            >
                <b-icon
                    style="width: 35%"
                    icon="three-dots"
                    animation="cylon"
                    font-scale="4"
                ></b-icon>
            </div>
            <b-button
                class="mt-2 mb-5"
                variant="outline-danger"
                block
                @click="start(true)"
                >{{ "Workshop starten" }}</b-button
            >
        </b-container>
        <b-container v-if="startWorkshop">
            <div class="container code" v-if="levels.length != 0">
                <div
                    v-for="level in levels"
                    v-bind:key="level.stage"
                    @click="setActive(level)"
                    :class="level.style"
                >
                    {{ level.stage }}
                    <span><b-icon :icon="level.icon"></b-icon></span>
                </div>
                <div style="padding-top: 20px;">
                    <span class="metainfo">
                        <span
                            v-html="
                                levels[activeLevel]
                                    ? levels[activeLevel].metainfo
                                    : ''
                            "
                        ></span>
                    </span>
                </div>
            </div>
            <br />
            <div class="container code">
                <span v-if="levels.length != 0">
                    <div
                        v-for="code in codeBox"
                        v-bind:key="code.index"
                        :class="'codeBox ' + code.style"
                    >
                        {{ code.value }}
                    </div>
                </span>

                <div class="timer" id="timerContainer">
                    <timer
                        id="timer"
                        :time_limit="getTime"
                        :stopTime="stopTimer"
                        :startTime="startTimer"
                        :continueTime="continueTimer"
                        @timesUp="timesUp()"
                    />
                </div>
            </div>

            <!--Number is : {{ number }}<br />-->
            <br />
            <!--<a href="#" @click="showKeypad = !showKeypad"><h4>Hide</h4></a>-->
            <numericpad
                v-if="levels.length != 0"
                :onInput="onInput"
                :onOk="onOk"
                :onReset="onReset"
                :show="showKeypad"
            />

            <div class="text-center mt-1 mb-4" v-if="mediaLoaded">
                <br />
                <h3>Medien</h3>
                <videoPlayer
                    :showPdf="workshop.showPdf"
                    :showVideo="workshop.showVideo"
                    :showOthers="workshop.showOthers"
                    :videos="workshop.videos"
                    :files="workshop.files"
                    :documents="workshop.documents"
                    :typ="videoName"
                    :workshop="workshop"
                ></videoPlayer>
            </div>
        </b-container>

        <b-container v-if="startWorkshop" class="last">
            <b-button
                class="mt-2"
                variant="outline-danger"
                block
                @click="start(false)"
                >{{ "Workshop beenden" }}</b-button
            >
        </b-container>

        <div class="lds-roller" :style="getLoading">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import numericpad from "../components/numeric-keyboard";
import timer from "../components/timer";
import jsPDF from "jspdf";
import moment from "moment";
import NoSleep from "nosleep.js";
import videoPlayer from "./video/video.vue";
import WorkshopService from "../workshops/workshops.service";
import { debounce } from "lodash";

export default {
    name: "workshops",
    components: {
        numericpad,
        timer,
        videoPlayer
    },
    data() {
        return {
            mediaLoaded: false,
            loadingWorkshops: false,
            loadingDownload: false,
            timeIsUp: false,
            globalTime: false,
            startWorkshop: false,
            activeLevel: 0,
            stopTimer: false,
            startTimer: false,
            continueTimer: false,
            n: 0,
            num: 0,
            number: "",
            maxLength: 5,
            codeBox: [],
            showKeypad: true,
            levels: [],
            keyStep: 0,
            generatePDF: false,
            loading: false,
            workshopSelection: [],
            workshop: [],
            fields: [
                {
                    key: "stage",
                    sortable: true,
                    label: "Stage"
                }
            ],
            image: null,
            videoName: null,
            location: null
        };
    },
    computed: {
        ...mapGetters({
            workshopkey: "workshop/workshopkey"
        }),
        getIcon() {
            let icon = "trophy";
            if (this.loading) {
                icon = "clock";
            } else {
                icon = "trophy";
            }
            return icon;
        },
        getTime() {
            let time = 0;
            if (this.workshop.time != null && this.workshop.time != 0) {
                time = this.workshop.time;
                this.toggleGlobalTime(true);
            } else {
                time = this.levels[this.activeLevel]
                    ? this.levels[this.activeLevel].time
                    : 0;
                this.toggleGlobalTime(false);
            }
            return time;
        },
        getLoading() {
            let style = "";
            if (this.loading) {
                style = "";
            } else {
                style = "display: none;";
            }
            return style;
        },
        getFolderSection() {
            return this.workshop.section;
        },
        getloadingDownload() {
            return this.loadingDownload;
        }
    },
    mounted() {
        this.handleDebouncedScroll = debounce(this.scrollHandler, 100);
        window.addEventListener("scroll", this.handleDebouncedScroll);
        this.initialQuiz();
        this.location =
            window.location.origin + process.env.BASE_URL + "media/";
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.handleDebouncedScroll);
    },
    methods: {
        scrollHandler() {
            let el = document.getElementById("timer");
            let elLabel = document.getElementById("timerlabel");

            if (el && elLabel) {
                const box = el.getBoundingClientRect();
                //console.log(window.innerHeight);
                if (box.y <= 0 && window.pageYOffset > 200) {
                    el.classList.add("resize");
                    elLabel.classList.add("resize");
                } else {
                    el.classList.remove("resize");
                    elLabel.classList.remove("resize");
                }
            }
        },
        downloadPDFBefore() {
            this.loadingDownload = true;

            setTimeout(() => {
                this.downloadPDF();
            }, 100);
        },
        downloadPDF() {
            //this.loadingDownload = true;
            var image1 = document.getElementById("image").src;
            var image2 = document.getElementById("image2").src;
            var doc = new jsPDF({
                orientation: "landscape",
                compress: true
            });

            if (this.workshop.certAnyway) {
                doc.addImage(image2, "PNG", 0, 0, 300, 210);
            } else {
                doc.addImage(image1, "PNG", 0, 0, 300, 210);
            }

            doc.setTextColor(100);
            doc.setFontSize(30);
            doc.text(
                80,
                120,
                this.workshop.section + " / " + this.workshop.description
            );

            doc.setTextColor(100);
            doc.setFontSize(20);

            doc.text(
                133,
                170,
                moment(new Date())
                    .format("DD.MM.YYYY")
                    .toString()
            );
            //doc.save("Zertifikat.pdf");
            //window.location.assign(doc.output("bloburl"), "_blank");
            if (navigator.userAgent.match("CriOS")) {
                let newBlob = new Blob([doc.output], {
                    type: "application/pdf"
                });
                // For other browsers:
                // Create a link pointing to the ObjectURL containing the blob.
                const data = window.URL.createObjectURL(newBlob);
                let link = document.createElement("a");
                link.download = "Certificate.pdf";
                link.href = data;
                this.loadingDownload = false;
                window.open(link, "_blank");
            } else {
                doc.save("Zertifikat.pdf", { returnPromise: true })
                    .then(() => {
                        this.loadingDownload = false;
                    })
                    .catch(() => {
                        this.loadingDownload = false;
                    });
            }
        },
        cancelWS(val) {
            if (val) {
                if (this.workshop.certAnyway) {
                    this.$bvModal.show("bv-modal-success");
                } else {
                    this.$bvModal.hide("bv-modal-cancel");
                    this.startWorkshop = false;
                    this.$router.push({ path: "/" });
                }
            } else {
                this.$bvModal.hide("bv-modal-cancel");
            }
        },
        async initialQuiz() {
            this.mediaLoaded = false;
            this.loadingWorkshops = true;
            this.codeBox = [];
            for (let i = 0; i < 5; i++) {
                this.codeBox.push({
                    index: i,
                    value: "*",
                    style: ""
                });
            }

            if (this.workshopkey) {
                this.levels = [];
                this.$store
                    .dispatch("workshop/get", this.workshopkey)
                    .then(async val => {
                        if (val.length > 1) {
                            this.workshopSelection = val;
                        }
                        if (val.length === 1) {
                            for (let i = 0; i < val[0].levels.length; i++) {
                                let entry = val[0].levels[i];
                                entry.style = "levelBox";
                                entry.icon = "lock";
                                this.levels.push(entry);
                            }
                            this.workshop = val[0];

                            //this.workshop.videoCount = [{ vid: 1 }, { vid: 2 }];
                            //https://tuitworkspace/api/workshop/medialist/pocketcodei
                            await this.loadSectionMedia(this.workshop.section);

                            if (this.levels.length > 0) {
                                let el = document.getElementById(
                                    "timerContainer"
                                );
                                if (el) {
                                    el.classList.add("resize");
                                }
                            }
                        }
                        this.loadingWorkshops = false;
                    });
            } else {
                this.$router.push({ path: "/" });
            }
        },
        restrictedFolder() {
            let val = "";
            if (
                this.videoName == "science" &&
                (this.workshop.area1 == 1 || this.workshop.area2 == 1)
            ) {
                if (this.workshop.area1 == 1) {
                    val = this.videoName + "/oberstufe";
                }
                if (this.workshop.area2 == 1) {
                    val = this.videoName + "/unterstufe";
                }
            } else {
                if (
                    this.videoName == "co2" &&
                    (this.workshop.area1 == 1 ||
                        this.workshop.area2 == 1 ||
                        this.workshop.area3 == 1)
                ) {
                    if (this.workshop.area1 == 1) {
                        val = this.videoName + "/teil1";
                    }
                    if (this.workshop.area2 == 1) {
                        val = this.videoName + "/teil2";
                    }
                    if (this.workshop.area3 == 1) {
                        val = this.videoName + "/teil3";
                    }
                } else {
                    val = this.videoName;
                }
            }
            return val;
        },
        async loadSectionMedia(section) {
            this.videoName = section.replace(/\s/g, "").toLowerCase();
            let fullSection = this.restrictedFolder();
            await WorkshopService.getMediaList(fullSection).then(x => {
                //console.log(x);
                this.workshop.videos = [];
                this.workshop.documents = [];
                this.workshop.files = [];
                x.data.forEach(x => {
                    if (x.indexOf(".mp4") >= 0) {
                        this.workshop.videos.push({
                            vid: x
                            //vid: this.location + fullSection + "/" + x
                        });
                    } else {
                        if (
                            x.indexOf(".pdf") >= 0 &&
                            (this.workshop.downloadPdf == null ||
                                this.workshop.downloadPdf == false)
                        ) {
                            this.workshop.documents.push({
                                doc: x
                            });
                        } else {
                            this.workshop.files.push({
                                doc: x
                            });
                        }
                    }
                });
                if (
                    this.workshop.videos.length > 0 ||
                    this.workshop.documents.length > 0 ||
                    this.workshop.files.length > 0
                ) {
                    this.mediaLoaded = true;
                }
            });
        },
        selectLevel(workshop) {
            this.workshop = workshop;
            //this.loadSectionVideo(this.workshop.section);
            for (let i = 0; i < workshop.levels.length; i++) {
                let entry = workshop.levels[i];
                entry.style = "levelBox";
                entry.icon = "lock";
                this.levels.push(entry);
            }
            this.workshopSelection = [];
        },
        timeUpAction(val) {
            if (val) {
                this.start(true);
            } else {
                this.$router.push({ path: "/" });
            }
            this.$bvModal.hide("bv-modal-timeup");
        },
        timesUp() {
            console.log("Times UP");
            this.timeIsUp = true;
            if (this.workshop.levels.length > 0) {
                this.$bvModal.show("bv-modal-timeup");
            } else {
                this.$bvModal.show("bv-modal-success");
            }
        },
        toggleGlobalTime(val) {
            this.globalTime = val;
        },
        start(val) {
            if (val) {
                var isIE11 =
                    !!window.MSInputMethodContext && !!document.documentMode;
                if (!isIE11) {
                    var noSleep = new NoSleep();
                    console.log("start sleep");

                    noSleep.enable();
                }

                this.startWorkshop = true;
            } else {
                this.$bvModal.show("bv-modal-cancel");
            }

            if (this.startWorkshop && val) {
                this.initialQuiz();
            }
        },
        getActive(element) {
            if (element.active) {
                return "levelBox active";
            } else {
                return "levelBox";
            }
        },
        setActive(level) {
            level.active = true;
        },
        onInput(key) {
            if (key == 11) {
                key = 0;
            }
            this.number = (this.number + key).slice(0, this.maxLength);
            this.codeBox[this.keyStep].value = String(this.number).substring(
                this.keyStep,
                this.keyStep + 1
            );
            this.keyStep++;
        },
        onOk() {
            this.stopTimer = !this.stopTimer;
            this.loading = true;
            const INTERVAL = 1000; // in milliseconds
            this.codeBox.forEach((item, index) => {
                setTimeout(() => {
                    item.style = "validation";
                }, INTERVAL * index);
            });

            setTimeout(() => {
                this.loading = false;
                this.validateCode();
            }, INTERVAL * this.codeBox.length);
        },
        onReset() {
            this.codeBox.forEach(element => {
                element.value = "*";
                element.style = "";
            });
            this.keyStep = 0;
            this.number = "";
        },
        validateCode() {
            let temp = [...this.levels];

            let obj = {
                code: this.number,
                stage: this.levels[this.activeLevel].stage,
                workshop_id: this.levels[this.activeLevel].workshop_id
            };

            this.$store.dispatch("level/create", obj).then(val => {
                if (val.workshop && val.workshop.length > 0) {
                    temp[this.activeLevel].style = "levelBox success";
                    temp[this.activeLevel].icon = "unlock";

                    if (this.levels.length - 1 == this.activeLevel) {
                        this.$bvModal.show("bv-modal-success");
                    } else {
                        this.activeLevel++;
                        if (!this.globalTime) {
                            this.onReset();
                            this.startTimer = !this.startTimer;
                        } else {
                            this.continueTimer = !this.continueTimer;
                            this.onReset();
                        }
                    }
                } else {
                    temp[this.activeLevel].style = "levelBox error";
                    temp[this.activeLevel].icon = "lock";
                    for (let i = 0; i < val.validation.length; i++) {
                        if (val.validation[i] == false) {
                            this.codeBox[i].style = "validation-error";
                        }
                    }
                    if (!this.globalTime) {
                        //this.onReset();
                        this.startTimer = !this.startTimer;
                    } else {
                        this.continueTimer = !this.continueTimer;
                        //this.onReset();
                    }
                }
                this.levels = temp;
            });
        }
    }
};
</script>

<style scoped lang="scss">
.base-timer.resize {
    position: fixed !important;
    top: 0px !important;
    z-index: 999;
    width: 85px !important;
}

.timer.resize {
    padding-top: 20px;
}

.timer {
    min-height: 180px;
}

.levelBox {
    width: 100px;
    border-top: 5px solid rgb(228, 21, 75);
    color: orange;
    background-color: rgb(69, 45, 61);
    display: inline-table;
    height: 50px;
    padding: 20px;
    margin-right: 20px;
    text-align: center;
}

.codeBox {
    width: 100px;
    border-top: 5px solid rgb(228, 21, 75);
    color: white;
    display: inline-table;
    height: 50px;
    padding: 15px;
    margin-right: 20px;
    text-align: center;
    font-size: 2.5rem;

    background-image: linear-gradient(
        0deg,
        #ee617d 16.67%,
        #3d6f8e 16.67%,
        #3d6f8e 50%,
        #ee617d 50%,
        #ee617d 66.67%,
        #3d6f8e 66.67%,
        #3d6f8e 100%
    );
    background-size: 6px 6px;
}
@media only screen and (max-width: 600px) {
    .codeBox {
        display: table-cell;

        padding: 0px;
        margin-right: 0px;
    }
    .levelBox {
        margin-bottom: 10px;
        width: 60px !important;
        padding: 10px;
        margin-left: 20px;
        margin-right: 0px;
    }
}
.active {
    background-color: lightgreen;
}

.success {
    background-color: lightgreen;
}
.error {
    background-color: red;
}

.validation {
    background-image: linear-gradient(
        0deg,
        #12eb37 16.67%,
        #3d6f8e 16.67%,
        #3d6f8e 50%,
        #12eb37 50%,
        #12eb37 66.67%,
        #3d6f8e 66.67%,
        #3d6f8e 100%
    );
    background-size: 6px 6px;
    transition: background-color 2s;
}

.validation-error {
    background-image: linear-gradient(
        0deg,
        #ff0000 16.67%,
        #8c3838 16.67%,
        #8c3838 50%,
        #ff0000 50%,
        #ff0000 66.67%,
        #8c3838 66.67%,
        #8c3838 100%
    );
    background-size: 6px 6px;
    transition: background-color 2s;
}

.lds-roller {
    /* display: none; */
    position: fixed;
    left: 50%px;
    top: 50%;
    width: 100%;
    height: 100%;
    z-index: 9999999999;
    overflow: hidden;
    /* background: #fff; */
    left: 50%;
    margin-left: -3em;
}
.lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
}
.lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: black;
    margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
}
.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
}
.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
}
.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}
.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
}
.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
}
.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
}
.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}
@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.container.last {
    margin-bottom: 20px;
}
</style>
