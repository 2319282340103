<template>
    <div>
        <!--<iframe
            src="https://tuitworkspace/api/workshop/videos"
            width="100%"
        ></iframe>-->

        <div v-if="showVideo === 1 && location != null">
            <b-button
                v-if="showVideo === 1 && videos.length > 0"
                v-b-toggle.collapse-1
                class="mt-2"
                variant="outline-danger"
                block
                >Zeige Video</b-button
            >

            <b-collapse visible id="collapse-1">
                <b-card
                    style="justify-content: center; display: flex"
                    class="mt-5 video"
                    v-for="vid in videos"
                    :key="vid.vid"
                >
                    <video
                        width="100%"
                        height="65%"
                        controls
                        controlsList="nodownload noremoteplayback"
                        oncontextmenu="return false;"
                        playsinline
                        preload="true"
                    >
                        <source
                            id="source1"
                            v-bind:src="
                                location +
                                    'stream/' +
                                    restrictedFolder +
                                    '/' +
                                    vid.vid
                            "
                            type="video/mp4"
                        />
                    </video>
                </b-card>
            </b-collapse>
        </div>
        <div
            v-if="
                showPdf === 1 &&
                    location != null &&
                    (workshop.downloadPdf == null ||
                        workshop.downloadPdf == false)
            "
        >
            <b-button
                v-if="showPdf === 1 && documents.length > 0"
                v-b-toggle.collapse-3
                class="mt-2"
                variant="outline-danger"
                block
                >Zeige PDF</b-button
            >

            <b-collapse visible id="collapse-3">
                <b-card
                    v-for="doc in documents"
                    :key="doc.doc"
                    style="justify-content: center; display: flex"
                    class="mt-5"
                >
                    <!--<div id="pageContainer">
                        <PdfReader />
                    </div>-->
                    <!--<div class="embed-responsive" style="padding-bottom:150%">
                        <object
                            data="/document.pdf#toolbar=0&navpanes=1&scrollbar=1"
                            type="application/pdf"
                            width="100%"
                            height="100%"
                        ></object>
                    </div>-->
                    <vue-pdf-embed
                        v-bind:source="
                            location +
                                'stream/' +
                                restrictedFolder +
                                '/' +
                                doc.doc
                        "
                        :width="windowWidth"
                    />
                </b-card>
            </b-collapse>
        </div>
        <div v-if="showOthers === 1 && location != null">
            <b-button
                v-if="showOthers === 1 && files.length > 0"
                v-b-toggle.collapse-2
                class="mt-2"
                variant="outline-danger"
                block
                >Zeige Dokumente</b-button
            >

            <b-collapse visible id="collapse-2">
                <div
                    v-for="doc in files"
                    :key="doc.doc"
                    style="justify-content: center; display: flex"
                    class="mt-5"
                >
                    <b-button
                        @click="
                            downloadFile(
                                location +
                                    'stream/' +
                                    restrictedFolder +
                                    '/' +
                                    doc.doc
                            )
                        "
                        variant="outline-danger"
                    >
                        <b-icon icon="download"></b-icon>
                        {{ doc.doc }} downloaden
                    </b-button>
                </div>
            </b-collapse>
        </div>
    </div>
</template>

<script>
window.addEventListener(
    "contextmenu",
    function(e) {
        // do something here...
        e.preventDefault();
    },
    false
);

import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
//import PdfReader from "../pdf/PdfViewer.vue";
import ApiService from "@/api";

export default {
    name: "videoPlayer",
    components: {
        VuePdfEmbed
        //PdfReader
    },
    props: {
        typ: {
            type: String,
            default: ""
        },
        showVideo: {
            type: Number,
            default: 0
        },
        showPdf: {
            type: Number,
            default: 0
        },
        showOthers: {
            type: Number,
            default: 0
        },
        videos: {
            type: Array,
            default: null
        },
        documents: {
            type: Array,
            default: null
        },
        files: {
            type: Array,
            default: null
        },
        workshop: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            page: 1,
            numPages: 0,
            pdfdata: undefined,
            errors: [],
            scale: "page-width",
            location: null
        };
    },
    computed: {
        windowWidth() {
            return window.innerWidth > 900 ? 900 : window.innerWidth;
        },
        restrictedFolder() {
            let val = "";
            if (
                this.typ == "science" &&
                (this.workshop.area1 == 1 || this.workshop.area2 == 1)
            ) {
                if (this.workshop.area1 == 1) {
                    val = this.typ + "/oberstufe";
                }
                if (this.workshop.area2 == 1) {
                    val = this.typ + "/unterstufe";
                }
            } else {
                if (
                    this.typ == "co2" &&
                    (this.workshop.area1 == 1 ||
                        this.workshop.area2 == 1 ||
                        this.workshop.area3 == 1)
                ) {
                    if (this.workshop.area1 == 1) {
                        val = this.typ + "/teil1";
                    }
                    if (this.workshop.area2 == 1) {
                        val = this.typ + "/teil2";
                    }
                    if (this.workshop.area3 == 1) {
                        val = this.typ + "/teil3";
                    }
                } else {
                    val = this.typ;
                }
            }
            return val;
        }
    },
    mounted() {
        this.location = ApiService.getLocation();
    },
    methods: {
        findPos(obj) {
            return obj.offsetTop;
        },
        downloadFile(link) {
            window.open(link, "_blank");
        }
    }
};
</script>

<style scoped>
.video {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
}
.card-body {
    display: flex !important;
    justify-content: center !important;
    padding: 0px !important;
}
.vue-pdf-embed {
    max-width: 900px;
}

@media only screen and (max-width: 600px) {
    .vue-pdf-embed {
        width: 100%;
        overflow: scroll;
    }
}
</style>
